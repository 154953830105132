<template>
  <div
    class="d-flex flex-column flex-md-row"
    @mouseenter="loadImage"
  >
    <!--    Product top    -->
    <div
      class="position-relative image-size"
    >
      <!--    Product image    -->
      <b-img
        v-if="product.pictures[0] && product.pictures[0].preview"
        :src="product.pictures[0].preview.includes('blob') ? product.pictures[0].preview : `data:image/webp;base64,${product.pictures[0].preview}`"
        style="object-fit: cover"
        class="image-size rounded-left"
      />
      <div
        v-else
        class="image-size d-flex justify-content-center align-items-center bg-secondary bg-lighten-4 text-light"
      >
        <feather-icon
          icon="ImageIcon"
          size="40"
        />
      </div>
      <div>
        <router-link
          v-if="allowEdit"
          style="position:absolute; right: 10px; top: 10px"
          :to="{ name: 'productView', params: { productId: product.id } }"
          class="mr-1 mr-lg-0"
        >
          <feather-icon
            size="18"
            icon="SettingsIcon"
          />
        </router-link>

        <div style="position: absolute; right: 10px; top: 40px">
          <span
            v-if="checkRequiredModule('loyaltyModule') && product.shopProductLoyaltyPlans && product.shopProductLoyaltyPlans.length"
            :id="`product_row_loyaltyPlan_${product.id}`"
            class="ml-50 text-primary cursor-pointer"
          >
            <feather-icon
              size="19"
              icon="StarIcon"
            />
          </span>

          <span
            v-if="checkRequiredModule('marketingModule') && product.shopProductMarketingPlans && product.shopProductMarketingPlans.length"
            :id="`product_marketingPlan_${product.id}`"
            class="ml-50 text-primary cursor-pointer"
          >
            <feather-icon
              size="19"
              icon="LayersIcon"
            />
          </span>
        </div>

        <b-popover
          v-if="checkRequiredModule('marketingModule') && product.shopProductMarketingPlans && product.shopProductMarketingPlans.length"
          :target="`product_marketingPlan_${product.id}`"
          :triggers="['click', 'hover']"
          custom-class="loyaltyPlanPricePopover"
        >
          <div
            v-for="(plan, planIndex) in product.shopProductMarketingPlans"
            :key="`product_${product.id}_plan_popover_${planIndex}`"
          >
            <b-badge
              variant="light-primary"
              class="mb-25"
            >
              {{ plan.marketingPlan.name }}
            </b-badge>
            <div
              v-for="(commission, commissionIndex) in plan.shopProductMarketingPlanCommissions"
              :key="`product_${product.id}_plan_${plan.id}_commission_${commissionIndex}`"
              class="d-flex justify-content-between"
            >
              <div>{{ commission.productAmount }} {{ $t('Pro') }}.</div>
              <strong>{{ commission.value }} PLN</strong>
            </div>
          </div>
        </b-popover>

        <b-popover
          v-if="checkRequiredModule('loyaltyModule') && product.shopProductLoyaltyPlans && product.shopProductLoyaltyPlans.length"
          :target="`product_row_loyaltyPlan_${product.id}`"
          :triggers="['click', 'hover']"
          custom-class="loyaltyPlanPricePopover"
        >
          <div
            v-for="(plan, planIndex) in product.shopProductLoyaltyPlans"
            :key="`product_${product.id}_plan_popover_${planIndex}`"
          >
            <b-badge
              variant="light-primary"
              class="mb-25"
            >
              {{ plan.loyaltyPlan.name }}
            </b-badge>
            <div class="d-flex justify-content-between">
              <div>{{ $t('PointAwardedForSellingProduct') }}</div>
              <strong>{{ plan.pointsReward }}</strong>
            </div>
            <div class="d-flex justify-content-between">
              <div>{{ $t('PointOfTheProductInPurchase') }}</div>
              <strong>{{ plan.pointsPrice }}</strong>
            </div>
          </div>
        </b-popover>
      </div>
      <!--    Product badge    -->
      <div
        class="d-flex flex-column"
        style="position: absolute; left: 0; top: 10px"
      >
        <b-badge
          class="px-50 rounded-left ml-25"
          :variant="Boolean(product.shopProductStoragesSum) ? 'success' : 'danger'"
        >
          {{ Boolean(product.shopProductStoragesSum) ? $t('Available') : $t('Inaccessible') }}
        </b-badge>

        <div
          v-if="product.activeDiscount"
          v-b-tooltip
          class="mt-25 ml-25"
          :title="$t('Offer.Discount')"
        >
          <b-badge variant="primary">
            <feather-icon icon="DollarSignIcon" />
            {{ product.activeDiscount }}
          </b-badge>
        </div>
      </div>
    </div>

    <!--    Product    -->
    <div class="flex-grow-1">
      <div class="mt-1 mb-1 mx-1">
        <h5 class="mt-lg-2">
          <router-link
            :to="{ name: 'productView', params: { productId: product.id } }"
            class="font-weight-bolder"
          >
            <span class="doubleLineText">
              {{ ( selectedTranslationObject(product).name ) | truncate(45, '...') }}
            </span>
          </router-link>
        </h5>
        <h6
          class="d-inline-block font-small-3"
        >
          <b-badge
            v-if="product.shopCategories.length >= 1"
            variant="light-primary"
            size="sm"
          >
            {{ product.shopCategories[0].translations[0].name }}
          </b-badge>
          <b-badge
            v-if="product.shopCategories.length > 1"
            variant="light-primary"
            size="sm"
            class="ml-25"
          >
            {{ product.shopCategories[1].translations[0].name }}
          </b-badge>
        </h6>
        <br class="d-none d-lg-inline-block">
        <h5
          v-b-tooltip.hover.left
          class="mt-25 d-lg-none"
          :title="$t('Offer.GrossPrice')"
        >
          {{ product.offerPrice * ((product.vat / 100) + 1) || 0 | priceGrossFormat }} PLN
        </h5>
        <span
          class="d-lg-inline-block text-justify productDesc font-small-2 text-justify"
        >
          <span :inner-html.prop="selectedTranslationObject(product).description | truncate(350, '...')" />
        </span>
      </div>
    </div>

    <!--    Button lg    -->
    <div
      style="min-width: 250px"
      class="d-none d-lg-flex flex-column align-content-center px-2 justify-content-center border-left"
    >
      <div class="text-center mr-lg-25">
        <span class="font-small-1">{{ $t('offer.product.priceGross') }}</span>
        <h4 class="text-center text-primary">
          {{ product.offerPrice * ((product.vat / 100) + 1) || 0 | priceGrossFormat }} PLN
        </h4>
        <div v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])">
          <b-button
            v-if="!allowBuy"
            variant="primary"
            class="w-100"
            :disabled="!product.active"
            @click="$emit('addProductToCart', product)"
          >
            <feather-icon icon="ShoppingCartIcon" />
            <span class="ml-50">{{ $t('AddToOffer') }}</span>
          </b-button>
          <b-button
            v-else
            variant="danger"
            class="w-100"
            @click="$emit('remove-cart-product', product.id)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="ml-50">{{ $t('RemoveFromOffer') }}</span>
          </b-button>
        </div>
      </div>
    </div>

    <!--    Button Other    -->
    <div
      class="d-lg-none product-button"
    >
      <div
        v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])"
        v-b-tooltip
        class="w-100"
        :title="!Boolean(product.shopProductStoragesSum) ? $t('ProductNotAvailableInStorage') : ''"
      >
        <b-button
          v-if="!allowBuy"
          variant="primary"
          class="w-100"
          squared
          :disabled="!product.active || !Boolean(product.shopProductStoragesSum)"
          @click="$emit('addProductToCart', product)"
        >
          <feather-icon icon="ShoppingCartIcon" />
          <span class="ml-50">{{ $t('AddToOffer') }}</span>
        </b-button>
        <b-button
          v-else
          variant="danger"
          class="w-100"
          squared
          @click="$emit('remove-cart-product', product.id)"
        >
          <feather-icon icon="TrashIcon" />
          <span class="ml-50">{{ $t('RemoveFromOffer') }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg, BPopover, VBTooltip } from 'bootstrap-vue'
import { DOWNLOAD_FILE } from '@/@constants/mutations'
import { priceGrossFormat } from '@core/filters/parts/valueFormat'

export default {
  components: {
    BImg,
    // BFormSpinbutton,
    BPopover,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    selectedLocale: {
      default: 'pl_pl',
      type: String,
    },
    allowEdit: Boolean,
    product: Object,
    allowBuy: Boolean,
    showDetails: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isImageLoaded: false,
  }),
  watch: {
    allowBuy() {},
  },
  methods: {
    selectedTranslationObject(listProduct) {
      const { selectedLocale } = this

      return listProduct.translations.find(({ locale }) => locale === selectedLocale) || { name: '-', description: '' }
    },
    priceGrossFormat,
    async loadImage() {
      if (!this.isImageLoaded) {
        if (this.product.pictures?.length) {
          const file = this.product.pictures[0]
          this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken: file.token, filename: file.name })
            .then(fileRes => {
              const { data, filename } = fileRes
              // eslint-disable-next-line
              fetch(`data:${data.type};base64,${data.file}`)
                .then(test => test.blob())
                .then(blob => {
                  const blobFile = new File([blob], filename, { type: data.type })
                  // eslint-disable-next-line
                  file.preview = URL.createObjectURL(blobFile)
                  this.$set(this.product.pictures, 0, file)
                })
            })
        }
      }

      this.isImageLoaded = true
    },
  },
}
</script>

<style lang="scss">
.image-size {
  height: 200px;
  width: 200px;
}

@media (max-width: 768px) {
  .image-size {
    height: 200px !important;
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .product-button {
    button {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 40px !important;
      height: 40px !important;
      border-radius: 5px !important;
      padding: 0;
      span {
        display: none;
      }
    }
  }
}

.theView,
.cardTable {
  .productIcon {
    top : 0;
    left: 0;
  }

  ///    Product    ///
  .product {
    -webkit-transition: all 250ms;
    -moz-transition: all 250ms;
    transition: all 250ms;

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, .14), 0 10px 10px rgba(0, 0, 0, .14);

      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px);
      transform: translateY(-5px);
    }

    ///    Description    ///
    .productDesc {
      letter-spacing: .05rem;
    }
  }
}

.availableBadgeLeft,
.availableBadge {
  position: absolute;

  right: 0;

  opacity: .9;
}

.availableBadgeLeft {
  left : 0;
  right: auto;
}

.shopBorder {
  border-left: 1px solid var(--bg-shopItem-lighten-2)
}
</style>
