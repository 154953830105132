<template>
  <div class="pb-2">
    <!--    Filters    -->
    <b-card>
      <filters @reloadData="getContent" />

      <!-- Attributes Filters -->
      <div>
        <!-- toggle button -->
        <div class="d-flex">
          <a
            v-b-toggle.filters
            class="text-primary d-flex align-items-center"
            size="sm"
          >
            {{ $t('ShowMore') }}
            <b-badge
              v-b-tooltip
              class="ml-25"
              variant="light-primary"
              :title="$t('ActiveFilters')"
            >{{ getActiveFilters() }}</b-badge>
          </a>

          <div v-if="getActiveFilters()">
            <span class="px-50">|</span>
            <span
              class="text-danger cursor-pointer"
              @click="clearFilters"
            >
              {{ $t('Clear') }}
            </span>
          </div>
        </div>

        <b-collapse
          id="filters"
        >
          <hr>
          <b-row>
            <b-col
              v-for="attribute in attributes"
              :key="`filter_${attribute.id}`"
              sm="12"
              md="6"
              lg="4"
            >
              <b-form-group :label="attribute.translations[0].name">
                <b-form-input
                  v-if="['PERCENTAGE', 'TEXT', 'METRIC'].includes(attribute.type)"
                  v-model="attribute.value"
                  type="text"
                  size="sm"
                  @blur.enter="changeFilter"
                />

                <sw-select
                  v-else-if="['TIME', 'DATE'].includes(attribute.type)"
                  class="mb-0"
                >
                  <sw-select-fpr
                    @clear="attribute.value = ''"
                  >
                    <flat-pickr
                      :key="attribute.value"
                      v-model="attribute.value"
                      class="form-control form-control-sm"
                      :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale), ...timeConfig(attribute.type), defaultDate: attribute.value }"
                      @input="changeFilter"
                    />
                  </sw-select-fpr>
                </sw-select>

                <!-- Number Range -->
                <div
                  v-else-if="attribute.type === 'DIGITAL'"
                  class="d-flex"
                >
                  <b-input-group
                    :prepend="$t('From')"
                    size="sm"
                    class="mr-50"
                  >
                    <b-form-input
                      v-model="attribute.value[0]"
                      type="number"
                      size="sm"
                      @blur.enter="changeFilter"
                    />
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('To')"
                    size="sm"
                  >
                    <b-form-input
                      v-model="attribute.value[1]"
                      type="number"
                      size="sm"
                      @blur.enter="changeFilter"
                    />
                  </b-input-group>
                </div>

                <sw-select
                  v-else-if="['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(attribute.type)"
                  class="mb-0"
                >
                  <v-select
                    v-model="attribute.value"
                    :multiple="attribute.type === 'TEXT_MULTIPLE_ARRAY'"
                    :options="attribute.shopAttributeOptions"
                    :get-option-label="attribute => attribute.id"
                    class="select-size-sm"
                    @input="changeFilter"
                  >
                    <template #option="item">
                      {{ item.translations[0].name }}
                    </template>
                    <template #selected-option="item">
                      {{ item.translations[0].name }}
                    </template>
                    <template #no-options>
                      {{ $t('NoOptions') }}
                    </template>
                  </v-select>
                </sw-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>
      </div>
    </b-card>

    <!--    Products    -->
    <div class="mt-2">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="mb-50 d-flex flex-row align-items-center flex-wrap">
          <sw-select :classes="[]"
                     class="mb-0 pb-0"
          >
            <v-select
              v-model.trim="selectedLocale"
              :options="filteredLocales"
              :reduce="e => e.value"
              class="mb-0 bg-white mr-25 rounded"
              style="width: 175px"
              label="name"
            >
              <template #option="{ label }">
                {{ $t(label) }}
              </template>
              <template #selected-option="{ label }">
                {{ $t(label) }}
              </template>
            </v-select>
          </sw-select>
          <!--    Button: New Product    -->
          <b-button
            v-if="checkRequiredPermissions([$roles.SHOP_PRODUCT_MODIFY])"
            variant="primary"
            size="sm"
            :to="{ name: 'productEdit' }"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('offer.product.new') }}
          </b-button>
          <!--    Button: Mass price change    -->
          <b-button
            v-if="checkRequiredPermissions([$roles.SHOP_PRODUCT_MODIFY])"
            variant="primary"
            size="sm"
            class="ml-25"
            :disabled="massPriceUpdateLoading"
            @click="getProductsToMassPriceUpdate"
          >
            <feather-icon icon="DatabaseIcon" />
            {{ $t('RecordsMassUpdate') }}
          </b-button>
          <b-button
            v-if="checkRequiredPermissions([$roles.SHOP_PRODUCT_MODIFY]) && checkRequiredHostnameFragments(['oze', 'lasland', 'zremontowani', 'hotelzimnik', '4b', 'campter', 'hipernet'])"
            variant="primary"
            size="sm"
            class="ml-25"
            :disabled="exportLoading"
            @click="getProductsToExport"
          >
            <span v-if="!exportLoading"><feather-icon icon="DownloadIcon" />Export</span>
            <span v-else>
              <b-spinner
                small
                variant="light"
              />
            </span>
          </b-button>
          <b-button
            v-if="checkRequiredPermissions([$roles.SHOP_PRODUCT_MODIFY]) && checkRequiredHostnameFragments(['oze', 'lasland', 'zremontowani', 'hotelzimnik', '4b', 'campter', 'hipernet'])"
            variant="primary"
            size="sm"
            class="ml-25"
            @click="showImport = true"
          >
            <feather-icon icon="UploadIcon" />
            Import
          </b-button>
        </div>
        <div class="mb-50">
          <b-button-toolbar size="sm">
            <b-button-group size="sm">
              <b-button
                variant="outline-primary"
                :disabled="viewSelected == 2"
                size="sm"
                @click="viewSelected = 2"
              >
                <feather-icon icon="ListIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="viewSelected == 0"
                size="sm"
                @click="viewSelected = 0"
              >
                <feather-icon icon="GridIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="viewSelected == 1"
                size="sm"
                @click="viewSelected = 1"
              >
                <feather-icon icon="ServerIcon" />
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </div>
      </div>
      <b-card>
        <!-- Mass Price Update -->
        <!-- eslint-disable -->
        <price-update-modal
          :show-modal.async="massPriceUpdateShow"
          :products.async="productsToMassUpdate"
          @close-modal="closeMassUpdateModal"
        />

        <!-- eslint-disable -->
        <price-update-modal
          :show-modal.async="massPriceUpdateShow"
          :products.async="productsToMassUpdate"
          @close-modal="closeMassUpdateModal"
        />

        <!--    Bar    -->
<!--        <div class="d-flex flex-column flex-md-row justify-content-between">-->
<!--          &lt;!&ndash; Searchbar &ndash;&gt;-->
<!--&lt;!&ndash;          <div class="ecommerce-searchbar mr-1 w-100">&ndash;&gt;-->
<!--&lt;!&ndash;            <b-input-group class="input-group-merge mr-2 search-input-group">&ndash;&gt;-->
<!--&lt;!&ndash;              <b-input-group-prepend is-text>&ndash;&gt;-->
<!--&lt;!&ndash;                <feather-icon icon="SearchIcon" />&ndash;&gt;-->
<!--&lt;!&ndash;              </b-input-group-prepend>&ndash;&gt;-->
<!--&lt;!&ndash;              <b-form-input&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="search"&ndash;&gt;-->
<!--&lt;!&ndash;                :placeholder="$t('Search')"&ndash;&gt;-->
<!--&lt;!&ndash;                class="input-search"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="getProducts"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </b-input-group>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->

<!--          &lt;!&ndash;    Switch Products View    &ndash;&gt;-->
<!--        </div>-->

        <!--    Products Content    -->
        <div class="mt-1">
          <!--    Grid View Products    -->
          <template v-if="viewSelected === 2">
            <!--    Row View Products    -->
            <!-- eslint-disable -->
            <sw-table
              table-id="f0c2e39a059f43beb6dd2ee405c19d2f"
              show-columns
              show-search
              :fields.async="productsFields"
              :pagination="pagination"
              @change-pagination="Object.assign(pagination, $event)"
              @reload-content="getProducts"
              @set-fields="productsFields = $event"
              @change-search-query="search = $event"
            >
              <template #table>
                <product-table
                  :pagination="pagination"
                  :product-fields="fields"
                  :busy="loading"
                  :products="products"
                  :total="pagination.totalRecords"
                  :sorter="sorter"
                  :selected-locale="selectedLocale"
                  @change-sort="changeSort"
                  @reloadData="getPaginationProducts"
                  @removeProduct="deleteItem"
                  @remove-cart-product="removeProduct($event)"
                  @toggleActivity="toggleActivity"
                  @addProductToCart="addProductToCart"
                  @copy="getProducts"
                />
              </template>
            </sw-table>
          </template>

          <div
            v-if="loading && viewSelected !== 2"
            class="d-flex justify-content-center"
          >
            <b-spinner
              variant="primary"
              class="m-5"
            />
          </div>

          <div
            v-if="!loading"
            class="theView"
          >
            <div
              v-if="!products.length && viewSelected !== 2"
              class="text-center py-1 text-primary font-weight-bold"
            >
              <feather-icon
                size="17"
                icon="XCircleIcon"
              />
              {{ $t('NoData') }}
            </div>

            <!--    No Table Views    -->
            <div v-if="products.length">
              <sw-table
                v-if="viewSelected === 1 || viewSelected === 0"
                table-id="e8b2a30cb8194115a7d189f04cb2cb81"
                :pagination="pagination"
                @change-pagination="Object.assign(pagination, $event)"
                @reload-content="getProducts"
              >
                <template #table>
                  <b-row
                    v-if="viewSelected === 0"
                    class="mt-50"
                  >
                    <b-col
                      v-for="(product, index) in products"
                      :key="product.id"
                      sm="12"
                      md="6"
                      lg="3"
                      class="mb-0"
                    >
                      <b-card
                        class="
                          d-flex
                          product
                          flex-column
                          overflow-hidden
                          border
                        "
                      >
                        <product
                          :allow-buy="disallowToBuy(product)"
                          :allow-edit="allowEdit"
                          :product="product"
                          :selected-locale="selectedLocale"
                          @addProductToCart="addProductToCart"
                          @remove-cart-product="removeProduct($event, index)"
                        />
                      </b-card>
                    </b-col>
                  </b-row>

                  <b-row
                    v-else-if="viewSelected === 1"
                    class="mt-50"
                  >
                    <b-col
                      v-for="(product, index) in products"
                      :key="product.id"
                      sm="12"
                    >
                      <!--    Grid View Products    -->

                      <!--    Row View Products    -->
                      <b-card class="product rounded overflow-hidden border d-flex flex-row">
                        <product-row
                          :allow-buy="disallowToBuy(product)"
                          :allow-edit="allowEdit"
                          :product="product"
                          :selected-locale="selectedLocale"
                          @addProductToCart="addProductToCart"
                          @remove-cart-product="removeProduct($event, index)"
                        />
                      </b-card>
                    </b-col>
                  </b-row>
                </template>
              </sw-table>
            </div>
          </div>
        </div>
      </b-card>
    </div>

    <!-- Related products modal -->
    <b-modal
      size="lg"
      :visible="!!relatedProducts"
      :title="$t('RelatedShopProducts')"
      button-size="sm"
      ok-only
      :ok-title="$t('Close')"
      ok-variant="secondary"
      @hide="relatedProducts = null"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="(relatedProduct, index) in relatedProducts"
          :key="`related_product_${index}`"
        >
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <div class="d-flex flex-column flex-md-row justify-content-between align-items-center flex-grow-1">
                <div class="flex-grow-1font-weight-bold ml-50 pr-1 mr-1">
                  {{ selectedTranslationObject(relatedProduct).name }}
                </div>

                <div class="mt-1 mb-50 my-md-0 ">
                  <b-button
                    v-if="!productsInCart.includes(relatedProduct.id)"
                    variant="primary"
                    size="sm"
                    class="text-nowrap"
                    @click="addProductToCart(relatedProduct)"
                  >
                    <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-25"
                    />
                    {{ relatedProduct.offerPrice | priceFormat }} PLN
                  </b-button>

                  <b-button
                    v-else
                    variant="danger"
                    size="sm"
                    class="text-nowrap"
                    @click="removeProduct(relatedProduct.id, findProductIndex(relatedProduct.id))"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-25"
                    />
                    {{ relatedProduct.offerPrice | priceFormat }} PLN
                  </b-button>
                </div>
              </div>
              <div
                class="ml-50 mt-25"
                v-html="selectedTranslationObject(relatedProduct).description"
              />
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>

      <!-- Import Modal -->
      <import-products-modal
              :show="showImport"
              @close-modal="showImport = false"
              @reload-content="getProducts"
      />

    <transition name="fade">
      <div
        v-if="selectedProducts.length && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])"
        class="btn position-fixed mr-25"
        style="z-index: 999; right: 3.4rem; bottom: .5rem; transition-delay: .14s;"
      >
        <b-button
          variant="success"
          :to="{ name: 'shop/checkout' }"
        >
          {{ $t('Offer.FinishYourOrder') }}

          <feather-icon
            icon="ShoppingCartIcon"
            size="16"
            class="ml-25"
          />
        </b-button>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  BButtonGroup, BButtonToolbar, BCollapse, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ADD_PRODUCT_TO_CART,
  DELETE_PRODUCT,
  DOWNLOAD_FILE,
  GET_ATTRIBUTE,
  GET_PRODUCTS,
  REMOVE_PRODUCT_FROM_CART,
  UPDATE_PRODUCT,
} from '@/@constants/mutations'
import Filters from '@/views/filters/OfferFilters.vue'
import ProductTable from '@/views/offers/product/ProductTable.vue'
// import productTable from './components/ProductTable.vue'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters } from 'vuex'
import moment from 'moment'
import PriceUpdateModal from '@/pages/shop/PriceUpdateModal.vue'
// import XLSX from 'xlsx'
import XLSX from 'xlsx'
import ImportProductsModal from '@/views/offers/shop/ImportProductsModal.vue'
import productRow from '../views/offers/shop/ProductRow.vue'
import product from '../views/offers/shop/Product.vue'

export default {
  name: 'SwShop',
  components: {
    ImportProductsModal,
    PriceUpdateModal,
    flatPickr,
    ProductTable,
    Filters,
    vSelect,
    BButtonToolbar,
    BButtonGroup,
    BCollapse,
    product,
    productRow,
    // productTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  data: () => ({
    showImport: false,
    flatPickerConfig: {
      locale: [pl, en],
      mode: 'range',
    },
    ///    Config    ///
    selectedLocale: 'pl_pl',
    localeKeys: [
      { value: 'pl_pl', label: 'pl_pl' },
      { value: 'en_uk', label: 'en_uk' },
      { value: 'de_de', label: 'de_de' },
      { value: 'ar_dz', label: 'ar_dz', requiredDomain: 'lasland' },
      { value: 'ko_kr', label: 'ko_kr', requiredDomain: 'lasland' },
      { value: 'zh_cn', label: 'zh_cn', requiredDomain: 'lasland' },
    ],
    allowEdit: false,
    loading: false,
    pagination: {
      perPage: 50,
      currentPage: 1,
      totalRecords: 100,
    },
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    ///    Filters    ///
    SelectGroup: '',
    SelectCategory: '',
    SelectSubcategory: '',
    SelectStatus: '',
    filterPriceNett: {
      min: null,
      max: null,
    },

    ///    Selected View    ///
    viewSelected: 2,

    ///    Products Items    ///
    totalRecords: 100,
    products: [],
    relatedProducts: null,
    massPriceUpdateShow: false,
    massPriceUpdateLoading: false,
    productsToMassUpdate: [],

    exportLoading: false,
    productsToExport: false,
    exportShow: [],

    // Filters
    search: '',
    attributes: [],
    filters: null,
    productsFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'addProduct', label: 'AddToOffer', visible: true },
      { key: 'name', label: 'Name', visible: true },
      {
        key: 'loyaltyPlan', label: 'LoyaltyPlan', visible: true, requiredModule: 'loyaltyModule',
      },
      {
        key: 'marketingPlan', label: 'MarketingPlan', visible: true, requiredModule: 'marketingModule',
      },
      { key: 'category', label: 'Offer.Category', visible: true },
      { key: 'subCategory', label: 'Offer.UnderCategory', visible: true },
      { key: 'userGroups', label: 'offer.Group', visible: true },
      { key: 'pictures', label: 'Picture', visible: true },
      { key: 'description', label: 'task.Description', visible: true },
      {
        key: 'offerPrice', label: 'offer.NettoPrice', visible: true, sortable: true,
      },
      {
        key: 'offerPriceGross', label: 'offer.GrossPrice', visible: true, sortable: true,
      },
      { key: 'vat', label: 'VAT', visible: true },
      { key: 'activeDiscount', label: 'Offer.Discount', visible: true },
      { key: 'active', label: 'isActive', visible: true },
      { key: 'webUrl', label: 'OutsideShop', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
    source: null,
  }),
  computed: {
    filteredLocales() {
      return this.localeKeys.filter(locale => !locale.requiredDomain || window.location.href.includes(locale.requiredDomain))
    },
    visibleFields() {
      return this.productsFields.filter(field => !field?.requiredModule || this.checkRequiredModule(field?.requiredModule))
    },
    fields() {
      return this.visibleFields.filter(field => field.visible)
    },
    ...mapGetters({
      productsInCart: 'cart/getCartProductsIds',
      selectedProducts: 'cart/getCartProducts',
    }),
    locales() {
      return this.$i18n.locale
    },
  },
  watch: {
    productsFields: {
      deep: true,
      handler(newValue) {
        localStorage.setItem('shop_fields', JSON.stringify(newValue))
      },
    },
  },
  created() {
    ///    Config for Admins    ///
    this.allowEdit = true
  },
  async mounted() {
    if (window.location.origin.includes('hydropure') || window.location.origin.includes('192')) {
      const removeFields = [
        'category',
        'subCategory',
        'userGroups',
        'pictures',
        'description',
      ]
      this.productsFields = this.productsFields.filter(e => !removeFields.includes(e.key))
      this.sorter.sortBy = 'offerPriceGross'
      this.sorter.sortDesc = false
    }

    if (localStorage.getItem('shop_fields')) {
      this.productsFields = JSON.parse(localStorage.getItem('shop_fields'))
    }
    if (localStorage.getItem('shop_sorter')) {
      Object.assign(this.sorter, JSON.parse(localStorage.getItem('shop_sorter')))
    }

    await this.initData()
  },
  methods: {
    selectedTranslationObject(listProduct) {
      const { selectedLocale } = this

      return listProduct.translations.find(({ locale }) => locale === selectedLocale) || { name: '-', description: '' }
    },
    disallowToBuy(productItem) {
      if (this.productsInCart.includes(productItem.id)) return true

      // if (this.selectedProducts.length && this.selectedProducts[0].shopProduct?.shopProductLoyaltyPlans?.length) {
      //   const selectedPlanId = this.selectedProducts[0].shopProduct?.shopProductLoyaltyPlans[0].loyaltyPlan?.id
      //
      //   if (selectedPlanId && productItem?.shopProductLoyaltyPlans?.length) {
      //     const productPlanId = productItem.shopProductLoyaltyPlans[0].loyaltyPlan?.id
      //
      //     return selectedPlanId !== productPlanId
      //   } return true
      // }

      return false
    },
    closeMassUpdateModal(skipReload = false) {
      this.$set(this, 'productsToMassUpdate', [])
      this.$set(this, 'massPriceUpdateShow', false)
      this.$set(this, 'massPriceUpdateLoading', false)

      // eslint-disable-next-line no-unused-expressions
      !skipReload && this.getProducts()
    },
    getActiveFilters() {
      const count = this.attributes.filter(attribute => {
        if (attribute?.value) {
          if (['PERCENTAGE', 'TEXT', 'METRIC', 'TIME', 'DATE', 'TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(attribute.type)) {
            return attribute.value.length || attribute.value?.id
          }
          if (attribute.type === 'DIGITAL') {
            if (attribute.value.length === 1) return attribute.value[0].length
            if (attribute.value.length === 2) return attribute.value[0].length || attribute.value[1].length
          }
        }

        return false
      }).length
      return count
    },
    changeSort(ctx) {
      Object.assign(this.sorter, ctx)
      localStorage.setItem('shop_sorter', JSON.stringify(this.sorter))

      this.getProducts()
    },
    timeConfig(type) {
      return type === 'TIME' ? { enableTime: true, noCalendar: true, dateFormat: 'H:i:ss' } : {}
    },
    findProductIndex(id) {
      const products = this.$store.getters['cart/getCartProducts']
      return products.findIndex(item => item?.shopProduct?.id === id) ?? -1
    },
    removeProduct(productId, index) {
      if (index === -1) return

      const products = this.$store.getters['cart/getCartProducts']

      const productItem = products.find(item => item.shopProduct.id === productId)

      this.$store.dispatch(`cart/${REMOVE_PRODUCT_FROM_CART}`, { productId: productItem.id })
        .then(() => {
          this.products.slice(index, 1)
          this.showToast('success', this.$i18n.t('ProductRemovedFromCard'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    getPaginationProducts(pagination, search) {
      this.$set(this, 'pagination', pagination)
      this.$set(this, 'search', search)

      this.getProduct()
    },
    changeFilter() {
      localStorage.setItem('shop_attributes', JSON.stringify(this.attributes))

      this.getProducts()
    },
    async initData() {
      this.loading = true

      await this.loadAttributes()
      if (localStorage.getItem('shop_attributes')) {
        const savedAttributes = JSON.parse(localStorage.getItem('shop_attributes'))
        savedAttributes.forEach(attribute => {
          const existed = this.attributes.findIndex(a => a.id === attribute.id)
          if (existed > -1) {
            this.$set(this.attributes[existed], 'value', attribute.value)
          }
        })
        // this.attributes = JSON.parse(localStorage.getItem('shop_attributes'))
        // console.log(this.attributes)
      }

      if (localStorage.getItem('shop_filters')) {
        this.filters = JSON.parse(localStorage.getItem('shop_filters'))
      }

      Promise.all([this.getProducts()])
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getContent(filters) {
      this.$set(this, 'filters', filters)
      localStorage.setItem('shop_filters', JSON.stringify(this.filters))

      this.getProducts()
    },
    async getProductsToExport() {
      this.exportLoading = true
      try {
        const chunksToLoad = Math.ceil(this.pagination.totalRecords / this.pagination.perPage)
        const requests = new Array(chunksToLoad).fill(null)
        const response = await Promise.all(requests.map((_, index) => {
          const payload = {
            search: this.search,
            filters: this.filters,
            attributeFilters: this.attributes,
            withFields: this.$fields.PRODUCTS_EXPORT,
            pagination: { ...this.pagination, currentPage: index + 1 },
            skip: true,
          }
          return this.$store.dispatch(`products/${GET_PRODUCTS}`, payload)
        }))

        const items = []
        response.forEach(res => {
          items.push(...res.data?.items)
        })

        this.$nextTick(() => {
          this.exportProducts(items)
        })
        // this.$set(this, 'productsToExport', items || [])
        // this.$set(this, 'exportShow', true)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.massPriceUpdateLoading = false
      }
    },
    /* eslint-disable */
    async exportProducts(productList) {
      const wb = XLSX.utils.book_new()
      wb.Props = {
        Title: 'SampleXlsx',
      }

      const order = [
        { key: 'id', label: '#' },
        { key: 'category', label: 'Offer.Category', visible: true },
        { key: 'subCategory', label: 'Offer.UnderCategory', visible: true },
        { key: 'splitName', label: 'Name' },
        { key: 'name', label: 'Name' },
        { key: 'description', label: 'Description' },
        { key: 'active', label: 'offer.product.active' },
        { key: 'code', label: 'offer.product.code' },
        { key: 'offerPrice', label: 'Offer.NetPrice' },
        { key: 'vat', label: 'Vat' },
        { key: 'webUrl', label: 'OutsideShopUrl' },
        { key: 'shopProductStoragesSum', label: 'CurrentStorageAmount' },
        { key: 'images', label: 'offer.product.pictures' },
        { key: 'attributes', label: 'offer.product.pictures' },
      ]

      const aoaToExport = [
        ...productList.map(t => order.map(({ key }) => {
          if (key === 'name') return this.getTranslationsField(t, 'name').substring(this.getTranslationsField(t, 'name').indexOf(' ')).trim()
          if (key === 'description') return this.getTranslationsField(t, 'description')
          if (key === 'splitName') return this.getTranslationsField(t, 'name').split(' ')[0]
          if (key === 'category') return t.shopCategories.length >= 1 ? this.getTranslationsField(t.shopCategories[0], 'name') : ''
          if (key === 'subCategory') return t.shopCategories.length > 1 ? this.getTranslationsField(t.shopCategories[1], 'name') : ''

          if (key === 'attributes') return this.getProductAttributes(t).flatMap(item => item)

          if (typeof t[key] === 'boolean') return t[key] ? 1 : 0

          return t[key] ?? ''
        }).flat(Infinity)),
      ]
      const longestAoaArrayLength = aoaToExport.reduce((maxLength, currentArray) => {
          const currentLength = currentArray.length;
          return currentLength > maxLength ? currentLength : maxLength;
      }, 0);
      const headers = order.filter(({ key }) => key !== 'attributes').map(({ label }) => this.$i18n.t(label))
      const attributesHeadersLength = (longestAoaArrayLength - headers.length) / 5
      for (let i = 0; i < attributesHeadersLength; i++) {
          headers.push('Id', 'Typ', 'Nazwa', 'Wartość', 'Widoczność na stronie')
      }

      aoaToExport.unshift(headers)

      wb.SheetNames.push('Sample')

      const ws = XLSX.utils.aoa_to_sheet(aoaToExport)
      wb.Sheets.Sample = ws

      XLSX.writeFile(wb, 'Sample.xlsx')

        this.exportLoading = false
    },
    getProductAttributes(product) {
        // type, name, value, visible WWW
        // shopAttribute.type, shopAttribute.translations.name , value, visibleOfferWWW
        if (!product.shopProductAttributes.length) return []

        return product.shopProductAttributes.map((attribute, index) => {
            return [
                attribute.id,
                this.$i18n.t(`offer.attribute.types.${attribute.shopAttribute.type}`),
                this.getTranslationsField(attribute.shopAttribute, 'name'),
                attribute.shopAttribute.type.includes('ARRAY') ? attribute.valueShopAttributeOptions.map(o => this.getTranslationsField(o, 'name')).join(', ') : attribute.value.trim(),
                attribute.visibleOfferWWW ? 1 : 0,
            ]
        })

        return []
    },
    async getProductsToMassPriceUpdate() {
      this.massPriceUpdateLoading = true
      try {
        const chunksToLoad = Math.ceil(this.pagination.totalRecords / this.pagination.perPage)
        const requests = new Array(chunksToLoad).fill(null)
        const response = await Promise.all(requests.map((_, index) => {
          const payload = {
            search: this.search,
            filters: this.filters,
            attributeFilters: this.attributes,
            withFields: this.$fields.OFFER_PRICE_CHANGE,
            pagination: { ...this.pagination, currentPage: index + 1 },
            skip: true,
          }
          return this.$store.dispatch(`products/${GET_PRODUCTS}`, payload)
        }))

        const items = []
        response.forEach(res => {
          items.push(...res.data?.items)
        })

        this.$set(this, 'productsToMassUpdate', items || [])
        this.$set(this, 'massPriceUpdateShow', true)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.massPriceUpdateLoading = false
      }
    },
    /* eslint-disable */
    getProducts() {
      return new Promise((resolve, reject) => {
        const payload = {
          search: this.search,
          pagination: this.pagination,
          filters: this.filters,
          attributeFilters: this.attributes,
          sorter: this.sorter,
        }
        this.loading = true
        this.$store.dispatch(`products/${GET_PRODUCTS}`, payload)
          .then(res => {
            this.pagination.totalRecords = res.data.totalItemCount
            let { items } = res.data
            items.forEach(item => {
              if (item?.shopProductPictures?.length) {
                item.pictures = item?.shopProductPictures.map(img => img.file)
              } else item.pictures = []

              // Check active discounts
              if (item?.shopProductDiscounts?.length) {
                const activeDiscount = item.shopProductDiscounts.find(discount => {
                  const startAt = moment(discount.startAt?.date, 'YYYY-MM-DD HH:mm:ss')
                  const endAt = moment(discount.endAt?.date, 'YYYY-MM-DD HH:mm:ss')
                  const now = moment()

                  if (now.isBetween(startAt, endAt)) {
                    return discount
                  }
                })

                item.activeDiscount = activeDiscount ? `${activeDiscount.value} ${activeDiscount.type === 'NETTO_VALUE' ? 'PLN' : '%'}` : 0
              } else item.activeDiscount = 0
            })

            // if (this.filterPriceNett?.min)
            //   items = items.filter(el => Number(this.filterPriceNett?.min || 0) <= Number(el.offerPrice))
            //
            // if (this.filterPriceNett?.max)
            //   items = items.filter(el => Number(this.filterPriceNett?.max || 0) >= Number(el.offerPrice))

            this.products = items
            this.downloadFilesPreview()
            this.loading = false
            resolve()
          })
          .catch(err => {
            if (err?.response?.data?.message) this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
            reject()
          })
      })
    },
    toggleActivity(item) {
      const status = !item.active
      const payload = {
        id: item.id,
        active: status,
      }
      this.$store.dispatch(`products/${UPDATE_PRODUCT}`, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          // eslint-disable-next-line
          item.active = status
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    deleteItem(item) {
      if (item?.id) {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(() => {
            this.deleteItemFromDatabase(item.id)
            this.getProducts()
          })
      }
    },
    deleteItemFromDatabase(id) {
      this.$store.dispatch(`products/${DELETE_PRODUCT}`, id)
        .then(() => {
          const index = this.products.findIndex(item => item.id === id)
          if (index !== -1) this.$delete(this.products, index)
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    ///    Get Attributes ///
    loadAttributes() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`attributes/${GET_ATTRIBUTE}`)
          .then(res => {
            this.attributes = (res.data.items.filter(item => item.active) || []).map(attribute => {
              const filter = attribute
              if (['PERCENTAGE', 'TEXT', 'METRIC', 'TIME', 'DATE'].includes(filter.type)) {
                // eslint-disable-next-line
                filter.value = ''
              } else if (['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(filter.type)) {
                // eslint-disable-next-line
                filter.value = []
              } else {
                filter.value = ['', '']
              }

              return filter
            })
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    async downloadFilesPreview() {
      /* eslint-disable */
      return
      for (let productIndex = 0; productIndex < this.products.length; productIndex++) {
        const item = this.products[productIndex]
        if (item.pictures?.length) {
          const file = item.pictures[0]
          await this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken: file.token, filename: file.name })
              .then(async fileRes => {
                const { data, filename } = fileRes
                // eslint-disable-next-line
                await fetch(`data:${data.type};base64,${data.file}`)
                    .then(test => test.blob())
                    .then(blob => {
                      const blobFile = new File([blob], filename, { type: data.type })
                      // eslint-disable-next-line
                      file.preview = URL.createObjectURL(blobFile)
                      this.$set(this.products[productIndex].pictures, 0, file)
                    })
              })
        }
      }
    },

    clearFilters() {
      this.attributes = this.attributes.map(attribute => {
        const filter = attribute
        if (['PERCENTAGE', 'TEXT', 'METRIC', 'TIME', 'DATE'].includes(filter.type)) {
          // eslint-disable-next-line
          filter.value = ''
        } else if (['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(filter.type)) {
          // eslint-disable-next-line
          filter.value = []
        } else {
          filter.value = ['', '']
        }

        return filter
      })

      this.changeFilter()
    },
    // Cart
    addProductToCart(productItem) {
      if (productItem?.relatedShopProducts?.length) {
        this.$set(this, 'relatedProducts', productItem.relatedShopProducts)
      }
      this.$store.dispatch(`cart/${ADD_PRODUCT_TO_CART}`, productItem)
        .then(() => {
          this.showToast('success', this.$i18n.t('ProductAddedToCard'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    }
    ,
  },
}
</script>

<style lang="scss" scoped>
.theView,
.cardTable {
  ///    Change padding of Card    ///
  .card-body {
    padding: 0 !important;

    .table-responsive {
      margin: 0 !important;
    }
  }
}
</style>

<style lang="scss">
.stickyFilterBar {
  position: sticky;

  top: 7rem;
}

.vue-slider {
  .vue-slider-rail {
    background: var(--bg-shopItem-lighten-1);

    border-radius: 10rem;
  }

  .vue-slider-process {
    background: var(--primary);
  }

  .vue-slider-dot {
    border       : 2px solid var(--primary);
    border-radius: 100%;

    background: var(--white);
  }

  .vue-slider-dot-tooltip-inner {
    padding: .2rem .4rem;

    border-radius: .4rem;

    background: var(--primary);

    color: var(--white);
  }
}
</style>
